import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Mixpanel from 'mixpanel-browser';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import { Auth0Provider } from "@auth0/auth0-react";
import { HelmetProvider } from 'react-helmet-async';




Mixpanel.init("f944b95d5075babfe7e7a8c416705724");
Amplify.configure(awsExports);
// dotenv.config();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Auth0Provider
    domain="dev-xzdxvgg2uklsvqrg.us.auth0.com"
    clientId="a4MVkS8sYFuUXc78C9G7owV428tvUOCH"
    authorizationParams={{
      redirect_uri: window.location.origin
    }}
  >
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
