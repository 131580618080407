import logo from './logo.svg';
import './App.css';

import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";

import Header from "./components/Header";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <div>
        <Header />
      </div>
    ),
  },
]);


function App() {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}
export default App;
